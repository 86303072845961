/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */

import './src/assets/styles/app.scss';

import React from 'react';
import GlobalContextProvider from './src/context/GlobalContextProvider';

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};
